<template>
    <div class="auto-1440">
        <div class="form">
            <a-form-model :model="form" ref="ruleForm" :rules="rules">
                <a-form-model-item>
                    <div class="title">
                        <span v-if="userInfo.Phone">修改手机号</span>
                        <span v-else>绑定手机号</span>
                    </div>
                </a-form-model-item>
                <a-form-model-item prop="phoneOld" v-if="userInfo.Phone">
                    <div class="form-item">
                        <div class="label">旧手机号码</div>
                        <div class="input">
                            <a-input
                                placeholder="请输入旧手机号码"
                                disabled
                                v-model="form.phoneOld"
                            />
                            <a-popover placement="right" trigger="hover">
                                <template slot="content">
                                    <p>
                                        如旧手机无法接收短信验证码，请联系客服修改
                                    </p>
                                </template>
                                <a-icon type="info-circle" />
                            </a-popover>
                        </div>
                    </div>
                </a-form-model-item>
                <a-form-model-item prop="codeOld" v-if="userInfo.Phone">
                    <div class="form-item">
                        <div class="label">验证码</div>
                        <div class="input">
                            <a-input
                                v-model="form.codeOld"
                                placeholder="请输入验证码"
                                :maxLength="6"
                            />
                            <a-button
                                class="login_btn ver"
                                @click="sendSMSOld($event)"
                                :class="!showCodeBtn2 ? 'not' : ''"
                            >
                                <span v-if="showCodeBtn2">获取验证码</span>
                                <span v-else>{{ count2 }}s</span>
                            </a-button>
                        </div>
                    </div>
                </a-form-model-item>
                <a-form-model-item prop="phone">
                    <div class="form-item">
                        <div class="label">手机号码</div>
                        <div class="input">
                            <a-input
                                placeholder="请输入手机号码"
                                v-model="form.phone"
                            />
                        </div>
                    </div>
                </a-form-model-item>
                <a-form-model-item prop="code">
                    <div class="form-item">
                        <div class="label">验证码</div>
                        <div class="input">
                            <a-input
                                v-model="form.code"
                                placeholder="请输入验证码"
                                :maxLength="6"
                            />
                            <a-button
                                class="login_btn ver"
                                @click="sendSMS($event)"
                                :class="!showCodeBtn ? 'not' : ''"
                            >
                                <span v-if="showCodeBtn">获取验证码</span>
                                <span v-else>{{ count }}s</span>
                            </a-button>
                        </div>
                    </div>
                </a-form-model-item>
                <a-form-model-item>
                    <div class="form-item">
                        <div class="label"></div>
                        <div class="input submit">
                            <a-button
                                :loading="loading"
                                class="login_btn ver"
                                @click="submit"
                            >
                                <span>确认修改</span>
                            </a-button>
                        </div>
                    </div>
                </a-form-model-item>
            </a-form-model>
        </div>
    </div>
</template>
<script>
import { UpdatePhone } from '@/api/user'
import { SendSMS } from '@/api/common'
import { Base64 } from 'js-base64'
import { mapState } from 'vuex'
export default {
    data() {
        let validatePhone = (rule, value, callback) => {
            const regMobile =
                /^0?(13[0-9]|14[1-9]|15[012356789]|16[56]|17[0-9]|18[0-9]|19[189])[0-9]{8}$/
            if (value === '') {
                callback(new Error('请输入手机号'))
            } else if (regMobile.test(value)) {
                if (this.userInfo.Phone) {
                    if (value == this.form.phoneOld) {
                        callback(new Error('新手机号不不能与旧手机一致！'))
                    } else {
                        callback()
                    }
                } else {
                    callback()
                }
                return
            } else {
                callback(new Error('请输入手机号'))
            }
        }
        return {
            form: {
                phoneOld: '',
                codeOld: '',
                phone: '',
                code: '',
            },
            // 接口获取的验证码
            code: '',
            // 计时器
            timer: '',
            count: '',
            showCodeBtn: true,

            code2: '',
            // 计时器
            timer2: '',
            count2: '',
            showCodeBtn2: true,
            // loading
            spinning: false,
            rules: {
                phone: [{ validator: validatePhone, trigger: 'change' }],
                code: [
                    {
                        required: true,
                        message: '请输入验证码',
                        trigger: 'blur',
                    },
                ],
                codeOld: [
                    {
                        required: true,
                        message: '请输入验证码',
                        trigger: 'blur',
                    },
                ],
            },
            loading: false,
            redirect: '',
            otherQuery: '',
        }
    },
    watch: {
        $route: {
            handler: function (route) {
                const query = route.query
                if (query) {
                    this.redirect = query.redirect
                    this.otherQuery = this.getOtherQuery(query)
                }
            },
            immediate: true,
        },
        userInfo: {
            handler({ Phone }) {
                if (Phone) {
                    this.form.phoneOld = Phone
                }
            },
            immediate: true,
        },
    },
    computed: {
        ...mapState({
            userInfo: state => state.user.userInfo,
        }),
    },
    methods: {
        sendSMS() {
            this.$refs.ruleForm.validateField(['phone'], (err, values) => {
                if (!err) {
                    SendSMS(this.form.phone)
                        .then(res => {
                            this.$message.success(res.msg)
                            this.code = res.data
                            let leftTime = 60
                            if (!this.timer) {
                                this.count = leftTime
                                this.showCodeBtn = false
                                // 开启定时器
                                this.timer = setInterval(() => {
                                    if (
                                        this.count > 0 &&
                                        this.count <= leftTime
                                    ) {
                                        this.count--
                                    } else {
                                        this.showCodeBtn = true
                                        clearInterval(this.timer)
                                        this.timer = null
                                    }
                                }, 1000)
                            }
                        })
                        .catch(e => {
                            console.log(e)
                            this.$message.error(e)
                        })
                } else {
                    console.log(err)
                }
            })
        },
        sendSMSOld() {
            this.$refs.ruleForm.validateField(['phoneOld'], (err, values) => {
                if (!err) {
                    SendSMS(this.form.phoneOld)
                        .then(res => {
                            this.$message.success(res.msg)
                            this.code2 = res.data
                            let leftTime = 60
                            if (!this.timer) {
                                this.count2 = leftTime
                                this.showCodeBtn2 = false
                                // 开启定时器
                                this.timer2 = setInterval(() => {
                                    if (
                                        this.count2 > 0 &&
                                        this.count2 <= leftTime
                                    ) {
                                        this.count2--
                                    } else {
                                        this.showCodeBtn2 = true
                                        clearInterval(this.timer2)
                                        this.timer2 = null
                                    }
                                }, 1000)
                            }
                        })
                        .catch(e => {
                            console.log(e)
                            this.$message.error(e)
                        })
                } else {
                    console.log(err)
                }
            })
        },
        submit() {
            this.$refs.ruleForm.validate(async valid => {
                if (valid) {
                    this.loading = true
                    UpdatePhone(Base64.encode(JSON.stringify(this.form)))
                        .then(res => {
                            this.$message.success(res.msg)
                            this.$store
                                .dispatch('user/getUserInfo')
                                .then(res => {
                                    this.loading = false
                                    this.$router.push({
                                        path: this.redirect || '/infomation',
                                        query: decodeURIComponent(
                                            this.otherQuery
                                        ),
                                    })
                                })
                                .catch(err => {
                                    this.loading = false
                                })
                        })
                        .catch(err => {
                            this.loading = false
                        })
                }
            })
        },
        getOtherQuery(query) {
            return Object.keys(query).reduce((acc, cur) => {
                if (cur !== 'redirect') {
                    acc[cur] = query[cur]
                }
                return acc
            }, {})
        },
    },
}
</script>
<style scoped lang="less">
.auto-1440 {
    padding: 80px 0;
}
.title {
    text-align: center;
    font-size: 24px;
    padding-bottom: 20px;
    color: #333;
    margin-bottom: 20px;
    &::before {
        content: '';
        width: 80px;
        height: 6px;
        border-radius: 3px;
        background-color: #00ffc0;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
    }
}
.form {
    padding: 40px;
    background-color: #f5f5f5;
}
/deep/.ant-form {
    max-width: 700px;
    margin: 0 auto;
}
.form-item {
    display: flex;
    align-items: center;
    .input {
        flex: 1;
        display: flex;
        align-items: center;
        position: relative;
        :deep(.anticon-info-circle) {
            position: absolute;
            left: calc(100% + 15px);
            top: 50%;
            transform: translateY(-50%);
            margin: auto;
        }
    }
    .label {
        width: 85px;
        text-align: justify;
        text-align-last: justify;
        margin-right: 15px;
        font-size: 16px;
        font-weight: bold;
    }
    :deep(.ant-input) {
        height: 50px;
        line-height: 50px;
        border-radius: 5px;
    }
    :deep(.ant-input-disabled) {
        background-color: #eee;
    }
    .login_btn {
        min-width: 120px;
        height: 50px;
        line-height: 50px;
        margin-left: 15px;
        border-radius: 5px;
        background-color: #00ffc0;
        border-color: #00ffc0;
        font-weight: bold;
        font-size: 16px;
        &:hover {
            background-color: #79f9d9;
            border-color: #79f9d9;
            color: #000;
        }
        &:focus {
            color: #000;
        }
    }
}
:deep(.ant-form-explain) {
    padding-left: 90px;
}
.submit {
    text-align: center;
    justify-content: center;
    .ant-btn {
        min-width: 140px;
        margin-left: 0;
    }
}
</style>
